<template>
    <div class="content" :class="$style.isEcList">
        <h1 class="pagetitle" :class="$style.isEcList__pagetitle">
            {{ $_pagetitle }}
        </h1>
        <template v-if="!loader">
            <div v-for="(data, index) in readData" :key="`item${index}`" :class="$style.item">
                <div :class="$style.item__basic">
                    <div :class="[$style.item__basic__block, $style.itemImage]">
                        <img v-if="data.image[0]" :class="$style.item__basic__block__image" :src="data.image[0]" :alt="data.itemName" />
                    </div>
                    <div :class="$style.item__basic__block">
                        <h2 v-if="data.itemName" :class="$style.item__basic__block__title">
                            {{ data.itemName }}
                        </h2>
                        <div v-if="data.price" :class="$style.item__basic__block__price">
                            <span :class="$style.item__basic__block__price__tax">
                                税込
                            </span>
                            {{ data.price }}{{ field.price.unit }}
                        </div>
                        <p v-if="data.itemDescription" :class="$style.item__basic__block__text">
                            {{ data.itemDescription }}
                        </p>
                        <div :class="$style.item__basic__block__order">
                            <template v-if="0 < Number(data.stock)">
                                <button :class="$style.orderMinus" @click="minus(data)">
                                    -
                                </button>
                                <div :class="$style.orderCount">
                                    <input v-model="orderCount[data.id]" :max="data.stock" min="0" type="number" @input="validatedOrderCount(data)">
                                </div>
                                <button :class="$style.orderPlus" @click="plus(data)">
                                    +
                                </button>
                            </template>
                            <template v-else>
                                <div>
                                    在庫切れ
                                </div>
                            </template>
                        </div>
                        <div v-if="data.freeText || data.image[1] || data.image[2]" :class="$style.item__basic__block__link">
                            <Button
                                v-if="!flagDetail[index]"
                                :class="$style.item__basic__block__link__button"
                                text="詳細を開く"
                                type="textlink"
                                @action="openedDetail(index)"
                            />
                            <Button
                                v-else
                                :class="$style.item__basic__block__link__button"
                                text="詳細を閉じる"
                                type="textlink"
                                @action="closedDetail(index)"
                            />
                        </div>
                    </div>
                </div>
                <div v-show="flagDetail[index]" :class="$style.item__detail">
                    <div :class="[$style.item__detail__block, $style.itemDetailFlex]">
                        <img v-if="data.image[1]" :class="$style.item__detail__block__image" :src="data.image[1]" :alt="data.itemName">
                        <img v-if="data.image[2]" :class="$style.item__detail__block__image" :src="data.image[2]" :alt="data.itemName">
                    </div>
                    <p v-if="data.freeText" :class="$style.item__detail__block">
                        {{ data.freeText }}
                    </p>
                </div>
            </div>
        </template>
        <div v-else :class="$style.loader">
            <Loader
                color="#fff"
                :loader="loader"
            />
        </div>
        <div class="buttonArea">
            <Button
                :disabled="invalid"
                text="お客様情報入力へ進む"
                type="next"
                @action="goToNext()"
            />
        </div>
    </div>
</template>

<script>
    "use strict";

    import field from "@/settings/field.js";

    import Button from "@/components/Button.vue";
    import Loader from "@/components/Loader.vue";

    import list from "@/mixins/list.js";

    export default {
        "components": {
            Button,
            Loader,
        },
        "mixins": [
            list,
        ],
        "props": {
            "name": {
                "type": String,
                "required": false,
            },
        },
        data() {
            return {
                "field": field,
                "flagDetail": {},
                "orderCount": {},
            };
        },
        "computed": {
            loader() {
                return this.readData.length > 0 ? false : true;
            },
            // 情報入力へ進むボタン用バリデーション
            invalid() {
                let disabled = true;
                for (const id in this.orderCount) {
                    if (Number(this.orderCount[id])) {
                        disabled = false;
                        break;
                    }
                    else {
                        disabled = true;
                    }
                }
                return disabled;
            },
        },
        "watch": {
            "readData": function() {
                this.setDefaultOrders();
                this.setFlagDetail();
            },
        },
        created() {
            this.$_reset();
            this.$_read();
        },
        "methods": {
            // 注文数の初期値をset
            setDefaultOrders() {
                for (const data of this.readData) {
                    this.$set(this.orderCount, data.id, 0);
                }
            },
            // IDごとの詳細表示flagをset
            setFlagDetail() {
                for (const index in this.readData) {
                    this.$set(this.flagDetail, index, false);
                }
            },
            // 注文数のバリデーション
            validatedOrderCount(data) {
                const id = data.id;
                const stock = Number(data.stock);
                const orderNumber = Number(this.orderCount[id]);
                if (orderNumber < 0) {
                    this.orderCount[id] = 0;
                }
                else if (stock < orderNumber) {
                    this.orderCount[id] = stock;
                }
                else {
                    this.orderCount[id] = Math.floor(orderNumber);
                }
            },
            // 注文数を増やす
            plus(data) {
                const id = data.id;
                const stock = Number(data.stock);
                const orderNumber = Number(this.orderCount[id]);
                if(stock < orderNumber + 1) {
                    this.orderCount[id] = stock;
                }
                else {
                    this.orderCount[id] = orderNumber + 1;
                }
            },
            // 注文数を減らす
            minus(data) {
                const id = data.id;
                const orderNumber = Number(this.orderCount[id]);
                if(0 > orderNumber - 1) {
                    this.orderCount[id] = 0;
                }
                else {
                    this.orderCount[id] = orderNumber - 1;
                }
            },
            // 詳細を開く
            openedDetail(id) {
                this.flagDetail[id] = true;
            },
            // 詳細を閉じる
            closedDetail(id) {
                this.flagDetail[id] = false;
            },
            // 注文リストを作成
            adjustOrdersList() {
                const ordersList = {};
                const orderCount = this.orderCount;
                for (const key in orderCount) {
                    if (0 < orderCount[key]) {
                        ordersList[key] = orderCount[key];
                    }
                }
                return ordersList;
            },
            goToNext() {
                this.$router.push({
                    "name": "order",
                    "query": this.adjustOrdersList(),
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .loader {
        padding: 32px;
        text-align: center;
    }

    .isEcList {
        background: none;
        margin: 0;
        &__pagetitle {
            color: #fff;
        }
    }

    .item {
        background: #fff;
        border: 1px solid #ddd;
        margin: 16px 0 0;
        &__basic {
            display: flex;
            width: 100%;
            &__block {
                padding: 16px;
                width: 50%;
                &__title {
                    font-size: 16px;
                    line-height: 1.4;
                }
                &__price {
                    color: #e00;
                    font-size: 18px;
                    line-height: 1.6;
                    margin: 8px 0 0;
                    &__tax {
                        font-size: 12px;
                        margin-right: 2px;
                    }
                }
                &__text {
                    font-size: 12px;
                    line-height: 1.6;
                    margin: 8px 0 0;
                }
                &__order {
                    display: flex;
                    margin: 12px 0 0;
                    max-width: 160px;
                    p {
                        color: #e00;
                        font-size: 18px;
                        line-height: 1.2;
                        margin: 16px 0 0;
                    }
                }
                &__link {
                    margin: 24px 0 0;
                    &__button {
                        font-size: 14px;
                    }
                }
            }
        }
        &__detail {
            border-top: 1px solid #ddd;
            font-size: 12px;
            line-height: 1.6;
            padding: 16px;
        }
    }

    .itemImage {
        background: #f5f5f5;
    }

    .itemDetailFlex {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > img {
            margin: 0 0 5%;
            width: 47%;
        }
    }

    .orderMinus,
    .orderPlus {
        background-color: #c1272d;
        border-radius:5px;
        color: #fff;
        height: 32px;
        min-width: 32px;
    }

    .orderCount {
        display: block;
        flex: 1;
        margin: 0 8px;
        input {
            border-radius:5px;
            height: 32px;
        }
    }
</style>
